<template>
<!-- 我的空间和文件目录公用显示组件 -->
  <div class="folder-panel"
    v-loading="isLoading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="top-panel">
      <div class="path" :class="{'more': catalogTree.length===1}">
        <span v-for="(item,index) in catalogTree" :key="index">
          {{item}}
          <svg-icon icon-class="right" style="font-size:12px" v-if="index<catalogTree.length-1"></svg-icon>
        </span>
      </div>
    </div>
    <div class="list-main" v-if="listData.length>0">
      <div class="flexJK">
        <span class="file-name">名称</span>
        <span class="gd"></span>
        <span class="file-owner">所有者</span>
        <span class="gd"></span>
        <span class="file-updatetime">收藏时间</span>
        <span class="operate-btn">
          <svg-icon icon-class="list-view" v-if="nowType==='card'"></svg-icon>
          <svg-icon icon-class="card-view" v-if="nowType==='list'"></svg-icon>
        </span>
      </div>
    </div>
    <ul class="list-container" v-if="listData.length>0">
      <li class="list-main" v-for="item in listData" :key="item.id">
        <!-- 目录则打开下一级，文章则到文章展示页 -->
        <a @click="forDetail(item)" style="display:flex;flex: 1 1 0%; flex-direction: row">
          <span class="file-name">
            <span class="icon">
              <template v-if="item.hasArticle === 1">
                <svg-icon v-if="!item.type" icon-class="markdown_icon"></svg-icon>
                <svg-icon v-else-if="item.type==='newExcel'" icon-class="excel_icon"></svg-icon>
                <svg-icon v-else-if="item.type==='newPowerPoint'" icon-class="ppt"></svg-icon>
                <svg-icon v-else-if="item.type==='newMp4'" icon-class="mp4"></svg-icon>
                <svg-icon v-else-if="item.type==='newDoc'" icon-class="word"></svg-icon>
                <svg-icon v-else icon-class="word"></svg-icon>
              </template>
              <svg-icon v-else icon-class="folder_icon"></svg-icon>
            </span>
            <span class="name">
              <i v-if="item.origin === 2" class="el-icon-s-management"></i>{{item.name}}
            </span>
          </span>
          <span class="gd"></span>
          <span class="file-owner">{{item.username}}</span>
          <span class="file-updatetime">{{item.colloectTime}}</span>
        </a>
        <span class="operate-btn">
          <right-operate :treeData="item" @addFolderSuccess="addFolderSuccess" @deleteSuccess="deleteSuccess"></right-operate>
        </span>
      </li>
    </ul>
    <div v-if="listData.length===0" class="noData">
      <img src="../../../../public/static/img/noData.png" alt="">
      <p>这里空空如也，快去添加内容吧！</p>
    </div>
  </div>
</template>
<script>
import rightOperate from './rightOperate'
import { softDeleteSpace, getForId, addContentSpaceNew } from '@/api/space'
import { newFile } from '@/api/fileApi'
import { getCollectList } from '@/api/collect'

export default {
  components: {
    rightOperate
  },
  name: 'folderView',
  data() {
    return {
      nowType: 'list',
      listData: [],
      visible: false,
      file_type: null,
      uploadVisible: false,
      loading: false,
      isLoading: false
      // departId: null
    }
  },
  props: {
  },
  computed: {
    catalogTree() {
      if (this.$store.getters.catalogTree.length === 0) {
        return ['收藏']
      } else {
        return this.$store.getters.catalogTree
      }
    }
  },
  watch: {
    catalogTree: {
      handler(val) {
        // 刷新数据取sessionStorage的数据
        if (val && val.length > 0) {
          sessionStorage.setItem('catalogTree', JSON.stringify(val))
        } else if (sessionStorage.getItem('catalogTree')) {
          this.$store.dispatch('setCatalogTree', JSON.parse(sessionStorage.getItem('catalogTree')))
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.getChapterList()
  },
  methods: {
    getChapterList() {
      this.isLoading = true
      getCollectList().then((res) => {
        if (res.code === '000000' && res.data) {
          this.listData = res.data
        } else {
          this.listData = []
        }
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    addArticleClick() {
      if (this.encryptionStr === '-1') {
        this.addContentSpaceNode(-1)
      } else {
        this.isLoading = true
        getForId({ encryptionStr: this.encryptionStr }).then(res => {
          if (res.code === '000000' && res.data) {
            console.log('当前节点的ID', res.data)
            this.addContentSpaceNode(res.data)
          } else {
            this.isLoading = false
          }
        }).catch(err => {
          this.isLoading = false
          console.log(err)
        })
      }
    },
    addContentSpaceNode(id) {
      const params = {
        parentId: id
      }
      addContentSpaceNew(params).then(response => {
        if (response.code === '000000' && response.data) {
          console.log('response', response.data)
          const { href } = this.$router.resolve({ path: '/wiki/article/' + response.data })
          window.open(href, '_blank')
        }
        this.isLoading = false
      }).catch(error => {
        console.log(error)
        this.isLoading = false
        // 创建节点失败，给出提示
      })
    },
    addFolderClick() {
      this.visible = true
    },
    closeTab() {
      this.visible = false
    },
    addFolderSuccess() {
      this.visible = false
      this.getChapterList()
    },
    forDetail(item) {
      console.log(item)
      // 是否是上传的文件
      if (item.type) {
        const Base64 = require('js-base64').Base64
        const urlCode = Base64.encode(sessionStorage.getItem('urlInfo'))
        if (item.type === 'newMp4' || item.type === 'other') {
          newFile({
            action: 'getFileDetail',
            code: urlCode,
            path: item.dpath
          }).then((res) => {
            if (item.type === 'other') {
              window.open(res.data.url, '_blank')
            } else {
              // 在新路由页面打开视频播放
              const { href } = this.$router.resolve({
                path: `/wiki/player?name=${res.data.detail.name}&url=${res.data.url}`
              })
              window.open(href, '_blank')
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          const href = `${process.env.VUE_APP_PHP_BASE_API}/api.php?action=onlyOfficeEdit&path=` + item.dpath + `&code=${urlCode}`
          window.open(href, '_blank')
        }
        return
      }else if (item.origin === 2) {
        const { href } = this.$router.resolve({ path: '/wiki/detail/' + item.encryptionStr })
        window.open(href, '_blank')
      } else if (item.hasArticle === 0) {
        // // 文件夹
        this.$router.push({
          path: '/drive/favorites/folder/' + item.encryptionStr
        })
        this.$store.dispatch('setCatalogTree', [...this.catalogTree, item.name])
      } else {
        // 文章
        const { href } = this.$router.resolve({ path: '/wiki/article/' + item.encryptionStr })
        window.open(href, '_blank')
      }
    },
    deleteSuccess() {
      this.getChapterList()
    },
    deleteBatch() {
      this.$confirm('是否要删除节点或文章?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const idList = this.listData.filter(item => item.checked).map(item => item.id)
          softDeleteSpace({ idList: idList }).then(response => {
            if (response.code === '000000') {
              this.$message.success(response.message)
              this.getChapterList()
            } else {
              this.$message.error(response.message)
            }
          }).catch(err => {
            console.log(err)
          })
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消删除操作'
          })
        })
    },
    // 上传文件
    uploadFileClick() {
      this.uploadVisible = true
    },
    closeDialog() {
      this.uploadVisible = false
    },
    uploadSuccess() {
      this.uploadVisible = false
      this.getChapterList()
    },
    addNewFileClick(file_type) {
      this.visible = true
      this.file_type = file_type
    }
  },
  destroyed() {
    sessionStorage.setItem('catalogTree', '')
  }
}
</script>
<style lang="scss" scoped>
.folder-panel {
  position: relative;
  font-size: 14px;
  color: #808183;
  height: 100%;
  display: flex;
  flex-direction: column;
  &.paddingBottom {
    padding-bottom: 40px;
  }
  .top-panel {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    padding-bottom: 10px;
    .path {
      font-size: 18px;
      color: #38383a;
      display: flex;
      &.more {
        font-weight: bold;
      }
    }
    .right-btn {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }
}

.right-panel {
  .operate-item {
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    &:hover {
      background-color: #ececee;
    }
    .icon {
      display: block;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 45px;
      margin-right: 10px;
      .svg-icon {
        width: 1.4em;
        height: 1.4em;
      }
      &.rightIcon {
        margin-left: auto;
      }
    }
    .operate {
      display: flex;
      align-items: center;
    }
  }
}
.list-main {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #d8dadb;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: #f4f6f7;
  }
  .checkbox {
    width: 24px;
    display: flex;
    flex: 0 0 auto;
  }
  .gd {
    width: 10px;
  }
  .file-name {
    display: flex;
    flex-grow: 8;
    flex-shrink: 1;
    flex-basis: 100px;
    padding-left: 0;
    .icon {
      display: block;
      width: 30px;
      height: 30px;
      .svg-icon {
        width: 1.4em;
        height: 1.4em;
        margin-top: 12px;
      }
    }
    .name {
      display: flex;
      white-space: nowrap;
      align-items: center;
      text-overflow:ellipsis;
      overflow:hidden;
      max-width: 500px;
    }
  }
  .file-owner {
    display: flex;
    flex-grow: 6;
    flex-shrink: 1;
    padding-left: 0;
    flex-basis: 100px;
  }
  .file-updatetime {
    display: flex;
    flex-grow: 4;
    flex-shrink: 1;
    padding-left: 0;
    flex-basis: 100px;
  }
  .operate-btn {
    display: flex;
    flex: 0 0 auto;
    padding: 0 12px;
    .svg-icon {
      width: 1.2em;
      height: 1.2em;
      margin-top: 14px;
      cursor: pointer;
    }
  }
}
.list-container {
  flex: auto;
  height: calc(100vh - 170px);
  overflow-y: auto;
  .list-main {
    height: 50px;
    line-height: 50px;
    a {
      color: #808183;
    }
    &:hover {
      a {
        color: #409eff;
      }
    }
  }
  .checkbox-inner {
    margin-top: 15px;
  }
  .operate-btn {
    cursor: pointer;
    span {
      padding-top: 5px;
    }
    .svg-icon {
      margin-top: 16px !important;
    }
  }
}
.bottomFixed {
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: calc(100% - 260px);
  .list-main {
    height: 50px;
    border-top: 1px solid #d8dadb;
    border-bottom: 0 none;
    padding: 8px 0;
  }
}
.checkbox-inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin-top: 12px;
  background-color: #fff;
  z-index: 1;
  transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
  &:hover {
    border-color: #409eff;
    cursor: pointer;
  }
  &::after {
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 5px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg) scaleY(0);
    width: 3px;
    transition: transform .15s ease-in .05s;
    transform-origin: center;
  }
  // 全选
  &.allcheck {
    background-color: #409eff;
    border-color: #409eff;
    &::after {
      transform: rotate(45deg) scaleY(1);
    }
  }
  // 未全选
  &.somecheck {
    background-color: #409eff;
    border-color: #409eff;
    &::after {
      content: "";
      position: absolute;
      display: block;
      background-color: #fff;
      height: 2px;
      width: 12px;
      transform: scale(.5);
      left: 0;
      right: 0;
      top: 5px;
    }
  }
}
.flexJK {
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;
}
.noData {
  text-align: center;
  padding-top: 150px;
}
</style>
