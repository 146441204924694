<template>
  <div class="app-container">
    <table-view></table-view>
    <!-- <folder-view :title="title" :hasBtn="false" :hasSelect="false"></folder-view> -->
  </div>
</template>
<script>
// import folderView from '@/components/folderView/index.vue'
import tableView from './components/tableView'
export default {
  components: { tableView },
  data() {
    return {
      title: '收藏'
    }
  }
}
</script>
